import { Alert, AlertTitle } from "@mui/material";

const { useAlert } = require("hooks/UseAlert");

const GlobalAlert = () => {
  const { alertMessage, dismissAlert } = useAlert();

  return (
    alertMessage && (
      <div style={{ position: "fixed", right: "50px", top: "100px", zIndex: 10 }}>
        <Alert severity="warning" onClose={dismissAlert}>
          <AlertTitle>Warning</AlertTitle>
          {alertMessage}
        </Alert>
      </div>
    )
  );
};

export default GlobalAlert;
