import { TrackingParamNames } from "constants/tracking";
import { useTracking } from "hooks/UseTracking";
import MainLayout from "layouts/Warehouse/MainLayout";
import { useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import TrackingTimeline from "./TrackingTimeline";
import MKTypography from "components/MKTypography";
import { Icon } from "@mui/material";

const buttonStyles = {
  width: "max-content",
  display: "flex",
  alignItems: "center",
  "& .material-icons-round": {
    fontSize: "1.125rem",
    transform: `translateX(-3px)`,
    transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
  },

  "&:hover .material-icons-round, &:focus .material-icons-round": {
    transform: `translateX(-6px)`,
  },
};

const Tracking = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const shipDate = searchParams.get(TrackingParamNames.SHIP_DATE);
  const stopNumber = searchParams.get(TrackingParamNames.STOP_NUMBER);
  const routeId = searchParams.get(TrackingParamNames.ROUTE_ID);
  const assignmentId = searchParams.get(TrackingParamNames.ASSIGNMENT_ID);
  const { trackingInfo, isLoading } = useTracking({
    assignmentId,
    shipDate,
    stopNumber,
    routeId,
  });

  useEffect(() => {
    // if the user goes straight to the tracking page
    // send them back to the orders page to click on an shCode and come back
    if (!shipDate || !stopNumber || !routeId) {
      navigate("/orders");
    }
  }, []);

  return (
    <MainLayout title="Tracking" description="Follow the tracking information">
      <MKTypography
        component={Link}
        to="/orders"
        variant="body2"
        fontWeight="regular"
        color="info"
        sx={buttonStyles}
      >
        <Icon sx={{ fontWeight: "bold" }}>arrow_backward</Icon> Back to Orders page
      </MKTypography>
      <TrackingTimeline trackingInfo={trackingInfo} isLoading={isLoading} />
    </MainLayout>
  );
};

export default Tracking;
