/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Order page sections
import Search from "pages/Warehouse/Orders/Search";
import OrderTable from "pages/Warehouse/Orders/OrderTable";
import OrderMap from "./OrderMap";
import InvoiceList from "./InvoiceList";

// Images
import { useOrderStore } from "stores/Orders";
import MainLayout from "layouts/Warehouse/MainLayout";
import { Modes } from "stores/Orders";

function Orders() {
  const mode = useOrderStore((state) => state.mode);

  return (
    <MainLayout title="Orders" description="Input ship date or invoice number to start search">
      <Search />
      <MKBox display={mode === Modes.TABLE ? "block" : "none"}>
        <OrderTable />
      </MKBox>
      <MKBox display={mode === Modes.MAP ? "block" : "none"}>
        <OrderMap />
      </MKBox>
      <MKBox display={mode === Modes.FILES ? "block" : "none"}>
        <InvoiceList />
      </MKBox>
    </MainLayout>
  );
}

export default Orders;
