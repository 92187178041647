import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export const useSessionStore = create(
  persist(
    (set) => ({
      token: undefined,
      setToken: (token) => set({ token }),
      removeToken: () => set({ token: undefined }),
    }),
    {
      name: "session",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
