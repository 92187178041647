const companies = {
  custfdl: {
    subdomain: "custfdl",
    name: "FDL",
    logo: "/logos/fdlcold.jpeg",
    skipRouteId: false, // Deprecated
  },
  dntexpress: {
    subdomain: "dntexpress",
    name: "DNT Express",
    logo: "/logos/dntexpress.png",
    skipRouteId: false, // Deprecated
  },
  chilledsolutions: {
    subdomain: "chilledsolutions",
    name: "Chilled Solutions",
    logo: "/logos/cs_logo.jpg",
    skipRouteId: false, // Deprecated
  },
};

const subdomain = window.location.hostname.split(".")[0];
export const company = companies[subdomain] ?? companies["custfdl"];
