/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Authentication layout components
import CoverLayout from "layouts/Warehouse/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-in-cover.jpeg";

// Warehouse hooks and utils
import { useInput } from "hooks/UseInput";
import { post } from "api/HttpClient";
import MKAlert from "components/MKAlert";
import { Icon, InputAdornment } from "@mui/material";
import { People } from "@mui/icons-material";
import { useSessionStore } from "stores/Session";

function Login() {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const resetError = (error) => () => error && setError();
  const [userId, setUserId] = useInput("", resetError(error));
  const [password, setPassword] = useInput("", resetError(error));
  const setToken = useSessionStore((state) => state.setToken);

  const onSubmit = async () => {
    const body = JSON.stringify({
      username: userId,
      password,
    });

    try {
      const response = await post("/auth", { body });

      if (response.ok) {
        const resJson = await response.json();
        const token = resJson.jwtToken;
        setToken(token);
        navigate("/orders");
      } else {
        setError("Incorrect user ID or password.");
      }
    } catch (e) {
      setError("Network issue. Try again later.");
      console.error(e);
    }
  };

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MKBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Customer Portal
          </MKTypography>
          <MKTypography display="block" variant="button" color="white" my={1}>
            Enter your user id and password to Sign In
          </MKTypography>
        </MKBox>
        <MKBox pt={4} pb={3} px={3}>
          <MKBox component="form" role="form">
            <MKBox mb={2}>{error ? <MKAlert color="error">{error}</MKAlert> : null}</MKBox>
            <MKBox mb={2}>
              <MKInput
                type="text"
                label="User ID"
                variant="standard"
                fullWidth
                InputProps={{
                  value: userId,
                  onChange: setUserId,
                  endAdornment: (
                    <InputAdornment position="end">
                      <People fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                type="password"
                label="Password"
                variant="standard"
                fullWidth
                InputProps={{
                  value: password,
                  onChange: setPassword,
                  autoComplete: "off",
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon fontSize="small">lock_outline</Icon>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{ shrink: true }}
              />
            </MKBox>
            <MKBox mt={4} mb={1}>
              <MKButton variant="gradient" color="info" fullWidth onClick={onSubmit}>
                sign in
              </MKButton>
            </MKBox>
            <MKBox mt={3} mb={1} textAlign="center">
              <MKTypography variant="button" color="text">
                Forgot password?{" "}
                <MKTypography
                  component={Link}
                  to="/reset-password"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Reset Password
                </MKTypography>
              </MKTypography>
            </MKBox>
          </MKBox>
        </MKBox>
      </Card>
    </CoverLayout>
  );
}

export default Login;
