import { findOrders as findOrdersApi, findInvoices as findInvoicesApi } from "api/Orders";
import { useState } from "react";
import { useOrderStore } from "stores/Orders";

export const useOrders = () => {
  const orders = useOrderStore((state) => state.orders);
  const invoices = useOrderStore((state) => state.invoices);
  const setInvoices = useOrderStore((state) => state.setInvoices);
  const setOrders = useOrderStore((state) => state.setOrders);
  const isLoading = useOrderStore((state) => state.isLoading);
  const setIsLoading = useOrderStore((state) => state.setIsLoading);
  const [error, setError] = useState();

  const findOrders = async (shipDate, invoice) => {
    setIsLoading(true);
    setOrders(undefined);

    try {
      const orders = await findOrdersApi(shipDate, invoice);
      setOrders(orders);
      try {
        const invoices = await findInvoicesApi(shipDate, invoice);
        setInvoices(invoices);
      } catch (error) {
        console.error("failed to find invoices. suppress error to unblock other functions");
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    orders,
    invoices,
    error,
    findOrders,
    isLoading,
  };
};
