import { useSessionStore } from "stores/Session";

const HTTP_POST_METHOD = "POST";
const HTTP_GET_METHOD = "GET";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const getCmpToken = () => useSessionStore.getState().token;
const getUrl = (path) => `${SERVER_URL}${path}`;
const httpRequest = (path, options = {}) =>
  fetch(getUrl(path), {
    ...options,
    headers: { ...options.headers, "content-type": "application/json" },
  });
const get = (path, options = {}) => httpRequest(path, { ...options, method: HTTP_GET_METHOD });
const post = (path, options = {}) => httpRequest(path, { ...options, method: HTTP_POST_METHOD });
const httpRequestWithToken = (path, options = {}) =>
  httpRequest(path, {
    ...options,
    headers: { ...options.headers, Authorization: `Bearer ${getCmpToken()}` },
  });
const getWithToken = (path, options = {}) =>
  httpRequestWithToken(path, { ...options, method: HTTP_GET_METHOD });
const postWithToken = (path, options = {}) =>
  httpRequestWithToken(path, { ...options, method: HTTP_POST_METHOD });

export { getWithToken, postWithToken, get, post };
