import { getWithToken, postWithToken } from "./HttpClient";

export const findOrders = async (shipDate, invoice) => {
  const search = new URLSearchParams();
  search.set("shipdate", shipDate ?? "");
  search.set("invoice", invoice ?? "");

  return getWithToken(`/orders?${search}`)
    .then((res) => res.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error: ", error);

      throw error;
    });
};

export const findOrderBol = async (shipDate, shCode) => {
  try {
    const rawResponse = await getWithToken(`/orders/${shipDate}/${shCode}/bol`);
    return await rawResponse.json();
  } catch (error) {
    console.log("Error: ", error);

    throw error;
  }
};

export const findInvoices = async (shipDate, invoice) => {
  const search = new URLSearchParams();
  search.set("shipdate", shipDate ?? "");
  search.set("invoice", invoice ?? "");

  return getWithToken(`/invoices?${search}`)
    .then((res) => res.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error: ", error);

      throw error;
    });
};

export const findOrderBolByPath = async (bolPath) => {
  try {
    const rawResponse = await postWithToken(`/invoice/bol`, {
      body: JSON.stringify({ bolPath }),
    });
    return await rawResponse.json();
  } catch (error) {
    console.log("Error: ", error);

    throw error;
  }
};
