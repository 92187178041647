import { LinearProgress } from "@mui/material";
import MKBox from "components/MKBox";

const Loading = () => {
  return (
    <MKBox
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap="10px"
      width="100%"
      overflow="hidden"
    >
      <MKBox display="block" width="50%" overflow="hidden">
        <LinearProgress color="info" />
      </MKBox>
    </MKBox>
  );
};

export default Loading;
