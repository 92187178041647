import PropTypes from "prop-types";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { Icon, Paper, Typography } from "@mui/material";
import Loading from "components/Warehouse/Loading";
import InfoBox from "components/Warehouse/InfoBox";

const styleMapping = {
  Warehouse: { color: "secondary", icon: <Icon>store</Icon> },
  Truck: { color: "info", icon: <Icon>local_shipping</Icon> },
  "In Transit": { color: "info", icon: <Icon>local_shipping</Icon> },
  Delayed: { color: "error", icon: <Icon>local_shipping</Icon> },
  Delivered: { color: "success", icon: <Icon>location_on</Icon> },
  ETA: { color: "grey", icon: <Icon>location_on</Icon> },
};

const getAddress = (address) => {
  if (address) {
    return `${address.address1} ${address.address2}, ${address.city}, ${address.state}, ${address.zipcode}`;
  }
};

const TrackingComponent = ({ isLoading, trackingInfo }) => {
  if (isLoading) {
    return <Loading />;
  }

  if (!trackingInfo) {
    return null;
  }

  if (trackingInfo.length === 0) {
    return <InfoBox>No tracking info found</InfoBox>;
  }

  return (
    <>
      <Timeline position="alternate">
        {trackingInfo.map((t, index) => (
          <TimelineItem key={index}>
            <TimelineSeparator>
              <TimelineDot color={styleMapping[t.title]?.color} variant="outlined">
                {styleMapping[t.title]?.icon}
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper
                elevation={3}
                sx={{
                  padding: "6px 16px",
                  borderColor: t.done ? "green" : "grey",
                  borderWidth: "2px",
                  borderStyle: "solid",
                }}
              >
                <Typography variant="h6" component="h1">
                  {t.title}
                </Typography>
                <Typography>{t.message}</Typography>
                <Typography variant="h6">{t.liquorStoreName}</Typography>
                <Typography>{getAddress(t.liquorStoreAddress)}</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </>
  );
};

TrackingComponent.propTypes = {
  isLoading: PropTypes.bool,
  trackingInfo: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      message: PropTypes.string,
      liquorStoreName: PropTypes.string,
      liquorStoreAddress: PropTypes.shape({
        address1: PropTypes.string,
        address2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipcode: PropTypes.string,
      }),
      done: PropTypes.bool,
    })
  ),
};

export default TrackingComponent;
