/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "components/Warehouse/DefaultNavbar";

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/bg-rental.jpeg";
import { useSessionStore } from "stores/Session";
import { useNavigate } from "react-router-dom";
import { useOrderStore } from "stores/Orders";
import SimpleFooter from "examples/Footers/SimpleFooter";

function MainLayout({ children, title, description }) {
  const removeToken = useSessionStore((state) => state.removeToken);
  const resetOrders = useOrderStore((state) => state.resetOrders);
  const navigate = useNavigate();
  const logout = () => {
    removeToken();
    resetOrders();
    navigate("/login");
  };

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          onClick: logout,
          label: "logout",
          color: "info",
        }}
        transparent
        light
      />
      <MKBox
        minHeight="50vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.5),
              rgba(gradients.dark.state, 0.5)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            direction="column"
            item
            xs={12}
            lg={8}
            justifyContent="center"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h2"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              {title}
            </MKTypography>
            <MKTypography variant="body1" color="white" mt={1}>
              {description}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -16,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          overflow: "hidden",
        }}
      >
        {children}
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <SimpleFooter links={[]} company={{ href: "https://longwind.us/", name: "LongWind" }} />
      </MKBox>
    </>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default MainLayout;
