import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
  Box,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import _ from "lodash";
import { findOrderBol } from "api/Orders";
import { useOrderStore } from "stores/Orders";
import MKTypography from "components/MKTypography";
import { Link } from "react-router-dom";
import { useAlert } from "hooks/UseAlert";
import { useTrackingPrepare } from "hooks/UseTracking";
import Loading from "components/Warehouse/Loading";
import InfoBox from "components/Warehouse/InfoBox";
import { company } from "constants/companies";

const initSortStatus = {
  invoice: false,
  liquorStoreCity: false,
  liquorStoreState: false,
  status: false,
};

const getStatusTextAndColor = (status) => {
  let result;
  switch (status) {
    case "0":
    case "1":
      result = { status: "In Transit", color: "blue" };
      break;
    case "2":
      result = { status: "Delivered", color: "green" };
      break;
    case "3":
      result = { status: "Cancelled", color: "red" };
      break;
    default:
      result = { status: "In Transit", color: "blue" };
  }
  return result;
};

const CommentCell = ({ row }) => {
  const Ptdesc = () => {
    if (row.ptdesc) {
      return <div> {row.ptdesc} </div>;
    }
    return null;
  };

  const Notes = () => {
    if (row.comments) {
      return (
        <Tooltip title={row.comments}>
          <MKTypography component={Link} variant="body2" color="info" fontWeight="regular">
            Notes
          </MKTypography>
        </Tooltip>
      );
    }
    return null;
  };
  return (
    <div>
      <Ptdesc />
      <Notes />
    </div>
  );
};

CommentCell.propTypes = {
  row: PropTypes.shape({
    ptdesc: PropTypes.string,
    comments: PropTypes.string,
  }),
};

const SignatureCell = ({ row, shipDate }) => {
  const { showAlert, dismissAlert } = useAlert();
  const showAlertContent = (content) => {
    setTimeout(() => {
      dismissAlert();
    }, 5000);
    showAlert(content);
  };

  const onClick = async () => {
    try {
      const response = await findOrderBol(shipDate, row.shcode);
      if (response.bolPath) {
        window.open(response.bolPath, "_blank");
      } else {
        showAlertContent("There's no signature yet for this order, please try again later");
      }
    } catch (e) {
      console.log("Error!" + e);
      showAlertContent("oops! Something went wrong, please try again");
    }
  };

  return row.bolPath ? (
    <>
      <Tooltip title={`Click to open in a new window`}>
        <MKTypography
          component={Link}
          variant="body2"
          color="info"
          fontWeight="regular"
          onClick={onClick}
        >
          View
        </MKTypography>
      </Tooltip>
    </>
  ) : (
    <div />
  );
};

SignatureCell.propTypes = {
  row: PropTypes.shape({
    shcode: PropTypes.string,
    bolPath: PropTypes.string,
  }),
  shipDate: PropTypes.string,
};

const OrderTable = () => {
  const isLoading = useOrderStore((state) => state.isLoading);
  const orders = useOrderStore((state) => state.orders);
  const setOrders = useOrderStore((state) => state.setOrders);
  const [sortStatus, setSortStatus] = useState(initSortStatus);
  const { goToTrackingPage } = useTrackingPrepare();

  const sortOnField = (fieldName) => {
    return () => {
      if (sortStatus[fieldName]) {
        setOrders([..._.reverse(orders)]);
      } else {
        setOrders([..._.sortBy(orders, [fieldName])]);
      }
      setSortStatus({ ...sortStatus, [fieldName]: true });
    };
  };

  const onClickShCode = (row) => {
    goToTrackingPage({
      assignmentId: row.assignmentId,
      shipDate: row.shipdate,
      stopNumber: row.stopNumber,
      routeId: row.routeId,
    });
  };

  const clickableHeaderStyle = {
    "&:hover": {
      color: "red",
      cursor: "pointer",
    },
  };

  if (isLoading) {
    return <Loading />;
  }

  if (orders === undefined) {
    return null;
  }

  if (orders.length === 0) {
    return <InfoBox>No orders found</InfoBox>;
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 200 }} aria-label="order table">
        <TableHead sx={{ display: "table-header-group" }}>
          <TableRow>
            <TableCell sx={clickableHeaderStyle} onClick={sortOnField("invoice")} align="right">
              Invoice
            </TableCell>
            {company.skipRouteId ? null : (
              <TableCell sx={clickableHeaderStyle} onClick={sortOnField("routeId")} align="right">
                RouteId
              </TableCell>
            )}
            <TableCell align="right">StoreName</TableCell>
            <TableCell
              sx={clickableHeaderStyle}
              onClick={sortOnField("liquorStoreCity")}
              align="right"
            >
              City
            </TableCell>
            <TableCell
              sx={clickableHeaderStyle}
              onClick={sortOnField("liquorStoreState")}
              align="right"
            >
              State
            </TableCell>
            <TableCell sx={clickableHeaderStyle} onClick={sortOnField("status")} align="right">
              Status
            </TableCell>
            <TableCell align="right">Comments</TableCell>
            <TableCell align="right">Signature</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((row) => (
            <TableRow key={row._id}>
              <TableCell align="right">
                <Box
                  sx={{
                    "&:hover": {
                      color: "red",
                      textDecoration: "underline",
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => onClickShCode(row)}
                >
                  {row.invoice}
                </Box>
              </TableCell>
              {company.skipRouteId ? null : <TableCell align="right">{row.routeId}</TableCell>}
              <TableCell align="right">{row.liquorStoreName}</TableCell>
              <TableCell align="right">{row.liquorStoreCity}</TableCell>
              <TableCell align="right">{row.liquorStoreState}</TableCell>
              <TableCell
                align="right"
                style={{ color: `${getStatusTextAndColor(row.status).color}` }}
              >
                {getStatusTextAndColor(row.status).status}
              </TableCell>
              <TableCell align="right">
                <CommentCell row={row} />
              </TableCell>
              <TableCell align="right">
                {<SignatureCell row={row} shipDate={row.shipdate} />}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrderTable;
