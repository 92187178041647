import { useState } from "react";

/// initiate a piece specifically for input field, returns the state and the setter to grab the value in the input field and setState
/// Also apply any side effects passed in if it's passed in by the caller
export const useInput = (initValue, applySideEffects) => {
  const [value, setValue] = useState(initValue);
  const setInput = (e) => {
    setValue(e.target.value);
    applySideEffects && applySideEffects();
  };

  return [value, setInput];
};
