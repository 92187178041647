import { postWithToken } from "./HttpClient";

export const findTrackingInfo = async ({ assignmentId, shipDate, stopNumber, routeId }) => {
  try {
    const rawResponse = await postWithToken("/tracking", {
      body: JSON.stringify({
        assignmentId,
        shipDate,
        stopNumber,
        routeId,
      }),
    });
    return await rawResponse.json();
  } catch (error) {
    console.log("Error: ", error);

    throw error;
  }
};
