import MKBox from "components/MKBox";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { GoogleMap, LoadScript, Marker, MarkerClusterer } from "@react-google-maps/api";
import { useOrderStore } from "stores/Orders";
import Loading from "components/Warehouse/Loading";
import InfoBox from "components/Warehouse/InfoBox";

const OrderMap = () => {
  const isLoading = useOrderStore((state) => state.isLoading);
  const orders = useOrderStore((state) => state.orders);

  if (isLoading) {
    return <Loading />;
  }

  if (orders === undefined) {
    return null;
  }

  return (
    <MKBox component="section">
      <Container>
        {orders.length === 0 ? <InfoBox>No orders found</InfoBox> : null}
        <Grid
          container
          sx={{ margin: "20px auto 50px auto", textAlign: "center", height: "500px" }}
        >
          <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}>
            <GoogleMap
              mapContainerStyle={{ width: "100%", height: "100%" }}
              center={{ lat: 40.0583, lng: -74.4057 }}
              zoom={10}
              onLoad={(map) => {
                const latlngs = orders.map(
                  (order) => new window.google.maps.LatLng(order.lat, order.lng)
                );
                const latlngbounds = new window.google.maps.LatLngBounds();
                latlngs.forEach((latlng) => latlngbounds.extend(latlng));
                map.fitBounds(latlngbounds);
              }}
            >
              <MarkerClusterer
                options={{ imagePath: `${process.env.REACT_APP_SERVER_URL}/markerclusterer/m` }}
              >
                {(clusterer) =>
                  orders.map(({ lat, lng }, i) => (
                    <Marker
                      key={i}
                      position={{ lat: parseFloat(lat), lng: parseFloat(lng) }}
                      icon={`${process.env.REACT_APP_SERVER_URL}/bottle-10-32.ico`}
                      clusterer={clusterer}
                    />
                  ))
                }
              </MarkerClusterer>
            </GoogleMap>
          </LoadScript>
        </Grid>
      </Container>
    </MKBox>
  );
};

export default OrderMap;
