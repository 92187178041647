/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Authentication layout components
import CoverLayout from "layouts/Warehouse/CoverLayout";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";

// Warehouse
import { useInput } from "hooks/UseInput";
import { post } from "api/HttpClient";
import MKAlert from "components/MKAlert";
import { Icon, InputAdornment } from "@mui/material";
import { People } from "@mui/icons-material";

function ResetPassword() {
  const applySideEffect = (errorState) => {
    return () => {
      if (errorState) {
        setShowWrongUserNamePasswordError(false);
      }
      if (showSuccessBanner) {
        setShowSuccessBanner(false);
      }
    };
  };

  const [showWrongUserNamePasswordError, setShowWrongUserNamePasswordError] = useState(false);
  const [showConfirmPasswordError, setShowConfirmPasswordError] = useState(false);
  const [showDBError, setShowDBError] = useState(false);
  const [showSuccessBanner, setShowSuccessBanner] = useState(false);
  const [userId, setUserId] = useInput("", applySideEffect(showWrongUserNamePasswordError));
  const [currentPassword, setCurrentPassword] = useInput(
    "",
    applySideEffect(showWrongUserNamePasswordError)
  );
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const onNewPasswordInputChange = (e) => {
    setNewPassword(e.target.value);
    /// only if the confirm password field is not blank, we verify if they are the same
    if (confirmPassword && confirmPassword !== e.target.value) {
      setShowConfirmPasswordError(true);
    } else {
      setShowConfirmPasswordError(false);
    }
    setShowSuccessBanner(false);
  };

  const onConfirmPasswordInputChange = (e) => {
    setConfirmPassword(e.target.value);
    if (newPassword !== e.target.value) {
      setShowConfirmPasswordError(true);
    } else {
      setShowConfirmPasswordError(false);
    }
    setShowSuccessBanner(false);
  };

  const onSubmit = async () => {
    setShowSuccessBanner(false);
    const body = JSON.stringify({
      username: userId,
      currentPassword,
      newPassword,
    });
    try {
      const response = await post("/resetPassword", { body });

      const resJson = await response.json();
      if (response.ok && resJson.isSuccessful) {
        setShowSuccessBanner(true);
      } else if (response.ok && !resJson.isSuccessful) {
        setShowWrongUserNamePasswordError(true);
      } else {
        setShowDBError(true);
      }
    } catch (e) {
      setShowDBError(true);
      console.log(e);
    }
  };

  const submitButtonDisabled =
    showWrongUserNamePasswordError ||
    showWrongUserNamePasswordError ||
    !userId ||
    !currentPassword ||
    !newPassword ||
    !confirmPassword;

  return (
    <CoverLayout coverHeight="10vh" image={bgImage}>
      <Card>
        <MKBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MKTypography>
        </MKBox>
        <MKBox pt={4} pb={3} px={3}>
          <MKBox component="form" role="form">
            <MKBox mb={2}>
              {showConfirmPasswordError ? (
                <MKAlert color="error">
                  New password and confirm password has to be non empty and the same
                </MKAlert>
              ) : null}
              {showWrongUserNamePasswordError ? (
                <MKAlert color="error">Incorrect user ID or password, please try again</MKAlert>
              ) : null}
              {showDBError ? (
                <MKAlert color="error">Oops, something went wrong, please try again later</MKAlert>
              ) : null}
              {showSuccessBanner ? (
                <MKAlert color="success">
                  Password reset successfully, now you can go back to login page
                </MKAlert>
              ) : null}
            </MKBox>
            <MKBox mb={4}>
              <MKInput
                type="text"
                label="User ID"
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <People fontSize="small" />
                    </InputAdornment>
                  ),
                  value: userId,
                  onChange: setUserId,
                }}
              />
            </MKBox>
            <MKBox mb={4}>
              <MKInput
                type="password"
                label="Current password"
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon fontSize="small">lock_outline</Icon>
                    </InputAdornment>
                  ),
                  value: currentPassword,
                  onChange: setCurrentPassword,
                  autoComplete: "off",
                }}
              />
            </MKBox>
            <MKBox mb={4}>
              <MKInput
                type="password"
                label="New password"
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon fontSize="small">lock_outline</Icon>
                    </InputAdornment>
                  ),
                  value: newPassword,
                  onChange: onNewPasswordInputChange,
                  autoComplete: "off",
                }}
              />
            </MKBox>
            <MKBox mb={4}>
              <MKInput
                type="password"
                label="Confirm new password"
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon fontSize="small">lock_outline</Icon>
                    </InputAdornment>
                  ),
                  value: confirmPassword,
                  onChange: onConfirmPasswordInputChange,
                  autoComplete: "off",
                }}
              />
            </MKBox>
            <MKBox mt={6} mb={1}>
              <MKButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={onSubmit}
                disabled={submitButtonDisabled}
              >
                reset
              </MKButton>
            </MKBox>
          </MKBox>
        </MKBox>
      </Card>
    </CoverLayout>
  );
}

export default ResetPassword;
