import PropTypes from "prop-types";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

const InfoBox = ({ children }) => {
  return (
    <MKBox textAlign="center">
      <MKTypography>{children}</MKTypography>
    </MKBox>
  );
};

InfoBox.propTypes = {
  children: PropTypes.string.isRequired,
};

export default InfoBox;
