/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
// import MKDatePicker from "components/MKDatePicker";
import MKButton from "components/MKButton";
import { AppBar, CircularProgress, Icon, Tab, Tabs } from "@mui/material";
import { useOrderStore } from "stores/Orders";
import { useOrders } from "hooks/UseOrders";
import { CSVDownload } from "react-csv";
import { useEffect, useState } from "react";

function Search() {
  const isLoading = useOrderStore((state) => state.isLoading);
  const orders = useOrderStore((state) => state.orders);
  const shipDate = useOrderStore((state) => state.shipDate);
  const setShipDate = useOrderStore((state) => state.setShipDate);
  const invoice = useOrderStore((state) => state.invoice);
  const setInvoice = useOrderStore((state) => state.setInvoice);
  const mode = useOrderStore((state) => state.mode);
  const setMode = useOrderStore((state) => state.setMode);
  const { findOrders } = useOrders();
  const [downloadCsv, setDownloadCsv] = useState(false);

  useEffect(() => {
    downloadCsv && setDownloadCsv(false);
  }, [downloadCsv]);

  return (
    <MKBox component="section">
      <Container>
        <Grid container spacing={{ xs: 0, lg: 3 }} sx={{ pt: 2, pb: 3, px: 2, mx: "auto" }}>
          <Grid item xs={12} lg={2} sx={{ mt: 2 }}>
            <MKTypography display="block" variant="button" fontWeight="regular" color="text" mb={1}>
              Ship Date
            </MKTypography>
            <MKInput
              type="date"
              variant="standard"
              placeholder="Please select date"
              fullWidth
              value={shipDate}
              onChange={(e) => setShipDate(e.target.value)}
              disabled={isLoading}
            />
          </Grid>
          <Grid item xs={12} lg={2} sx={{ mt: 2 }}>
            <MKTypography display="block" variant="button" fontWeight="regular" color="text" mb={1}>
              Invoice No.
            </MKTypography>
            <MKInput
              variant="standard"
              placeholder="Invoice #"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={invoice}
              onChange={(e) => setInvoice(e.target.value)}
              disabled={isLoading}
            />
          </Grid>
          <Grid item xs={12} lg={2} sx={{ mt: 4 }}>
            <MKButton
              variant="gradient"
              color="info"
              fullWidth
              onClick={() => findOrders(shipDate, invoice)}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress color="white" size={16} /> : "search"}
            </MKButton>
          </Grid>
          {orders?.length ? (
            <Grid item xs={12} lg={3} sx={{ mt: 4 }}>
              <MKButton
                variant="outlined"
                color="dark"
                fullWidth
                onClick={() => setDownloadCsv(true)}
                disabled={isLoading}
              >
                download as csv
              </MKButton>
              {downloadCsv && <CSVDownload data={orders} target="_blank" />}
            </Grid>
          ) : null}
          <Grid item xs={12} lg={3} sx={{ mt: 4 }}>
            <AppBar position="static">
              <Tabs value={mode} onChange={(event, newMode) => setMode(newMode)}>
                <Tab label="Table" icon={<Icon>table_chart</Icon>} />
                <Tab label="Map" icon={<Icon>map</Icon>} />
                <Tab label="Files" icon={<Icon>insert_drive_file_icon</Icon>} />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Search;
