import { create } from "zustand";

const defaults = {
  isLoading: false,
  shipDate: "",
  invoice: "",
  mode: 0,
  orders: undefined,
  invoices: undefined,
};

export const Modes = Object.freeze({
  TABLE: 0,
  MAP: 1,
  FILES: 2,
});

export const useOrderStore = create((set) => ({
  ...defaults,
  setIsLoading: (isLoading) => set({ isLoading }),
  setShipDate: (shipDate) => set({ shipDate }),
  setInvoice: (invoice) => set({ invoice }),
  setMode: (mode) => set({ mode }),
  setOrders: (orders) => set({ orders }),
  setInvoices: (invoices) => set({ invoices }),
  resetOrders: () => set(defaults),
}));
