import { useNavigate } from "react-router-dom";
import { TrackingParamNames } from "constants/tracking";
import { useEffect, useState } from "react";
import { findTrackingInfo } from "api/Tracking";

export const useTracking = ({ assignmentId, shipDate, stopNumber, routeId }) => {
  const [isLoading, setIsLoading] = useState();
  const [trackingInfo, setTrackingInfo] = useState();

  const loadTrackingInfo = async () => {
    if (!(assignmentId || (shipDate && stopNumber && routeId))) return;

    setIsLoading(true);
    try {
      const data = await findTrackingInfo({
        assignmentId,
        shipDate,
        stopNumber,
        routeId,
      });
      setTrackingInfo(data);
    } catch (error) {
      setTrackingInfo([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadTrackingInfo();
  }, [assignmentId, shipDate, stopNumber, routeId]);

  return {
    isLoading,
    trackingInfo,
  };
};

export const useTrackingPrepare = () => {
  const navigate = useNavigate();

  const goToTrackingPage = ({ assignmentId, shipDate, stopNumber, routeId }) => {
    const search = new URLSearchParams({
      [TrackingParamNames.ASSIGNMENT_ID]: assignmentId,
      [TrackingParamNames.SHIP_DATE]: shipDate,
      [TrackingParamNames.STOP_NUMBER]: stopNumber,
      [TrackingParamNames.ROUTE_ID]: routeId,
    });
    navigate(`/tracking?${search}`);
  };

  return {
    goToTrackingPage,
  };
};
