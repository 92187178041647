const { useAlertStore } = require("stores/Alert");

export const useAlert = () => {
  const alertMessage = useAlertStore((state) => state.message);
  const setMessage = useAlertStore((state) => state.setMessage);

  const showAlert = (message) => {
    setMessage(message);
  };

  const dismissAlert = () => {
    setMessage();
  };

  return {
    alertMessage,
    showAlert,
    dismissAlert,
  };
};
