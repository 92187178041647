import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { postWithToken } from "api/HttpClient";
import LinearProgress from "@mui/material/LinearProgress";
import MKBox from "components/MKBox";

const GuardedRoute = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [waiting, setWaiting] = useState(true);
  const [auth, setAuth] = useState(false);

  useEffect(() => {
    postWithToken("/verifyToken")
      .then((res) => {
        setAuth(res.status != 401);
      })
      .catch((error) => {
        setAuth(false);

        // TODO: let user know what's wrong
        console.log(error);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => setWaiting(false), 1000);

    return () => clearTimeout(timeout);
  }, []);

  if (loading || waiting) {
    return (
      <MKBox
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap="10px"
        height="100vh"
        width="100%"
        overflow="hidden"
      >
        <MKBox display="block" width="50%" overflow="hidden">
          <LinearProgress color="info" />
        </MKBox>
      </MKBox>
    );
  }

  if (auth) {
    return <>{children}</>;
  } else {
    return <Navigate to="/login" />;
  }
};

GuardedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GuardedRoute;
