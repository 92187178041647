import { TableContainer, Paper, Button } from "@mui/material";
import { useOrderStore } from "stores/Orders";
import Loading from "components/Warehouse/Loading";
import InfoBox from "components/Warehouse/InfoBox";
import { findOrderBolByPath } from "api/Orders";
import { useAlert } from "hooks/UseAlert";

const InvoiceList = () => {
  const isLoading = useOrderStore((state) => state.isLoading);
  const invoices = useOrderStore((state) => state.invoices);

  const { showAlert, dismissAlert } = useAlert();
  const showAlertContent = (content) => {
    setTimeout(() => {
      dismissAlert();
    }, 5000);
    showAlert(content);
  };

  const onClick = async (bolPath) => {
    try {
      const response = await findOrderBolByPath(bolPath);
      if (response.bolPath) {
        window.open(response.bolPath, "_blank");
      } else {
        showAlertContent("There's no signature yet for this order, please try again later");
      }
    } catch (e) {
      console.log("Error!" + e);
      showAlertContent("oops! Something went wrong, please try again");
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  if (invoices === undefined) {
    return null;
  }

  if (invoices.length === 0) {
    return <InfoBox>No invoices found</InfoBox>;
  }

  return (
    <TableContainer component={Paper}>
      {invoices.map((invoice) => (
        <Button key={invoice.id} onClick={() => onClick(invoice.path)}>
          {invoice.id}
        </Button>
      ))}
    </TableContainer>
  );
};

export default InvoiceList;
